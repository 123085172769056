import Img from "gatsby-image";
import * as React from "react";
import { graphql } from "gatsby";

export const ContentfulAsset = graphql`
  fragment ContentfulAsset on ContentfulAsset {
    title
    description
    fluid(maxWidth: 750) {
      ...GatsbyContentfulFluid_withWebp
    }
    internal {
      type
    }
  }
`;

interface ImageProps {
  className?: string;
  src?: any;
}

export const Image = ({ className, src }: ImageProps) => {
  return src ? (
    <Img
      className={className}
      fluid={src.fluid}
      alt={src.title || src.description}
    />
  ) : null;
};
